import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import DisplayDate from 'components/wall/DisplayDate'
import api from "services/api";


const NotificationItem = ({notification, history, deleteNotification}) => {
  const {
    id,
    work_group_id,
    user_photography,
    user_photography_custom,
    user_name,
    activity_title,
    activity_created_at
  } = notification;
  const clickNotification = async () => {
    //Eliminar notificación
    const response = await api.notifications.deleteNotification(id);
    if (response.success) {
      // Redirigir al grupo de trabajo
      deleteNotification(id);
      history.push(`/app/work-groups/${work_group_id}`);
    }
  }
  return (
    <li className="media" onClick={clickNotification} style={{cursor: "pointer"}}>
      <Avatar
        alt="user"
        src={user_photography_custom || user_photography}
        className=" mr-2"
      />
      <div className="media-body align-self-center">
        <p className="sub-heading mb-0"><b>{user_name}</b> registró {activity_title}</p>
        <span className="meta-date">
          <i className={`zmdi zmdi-time zmdi-hc-fw`} />
        </span>
        <span className="meta-date"><small>{` `}<DisplayDate date={activity_created_at} /></small></span>
      </div>
    </li>
  );
};

export default NotificationItem;
