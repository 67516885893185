import user from "./user";
import blog from "./blog";
import notifications from "./notifications";
import workGroups from "./work-groups";
import procedures from "./procedures";
import appointments from "./appointments";

export default {
  user,
  blog,
  notifications,
  workGroups,
  procedures,
  appointments
};
