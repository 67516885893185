import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";

import { Button } from "@material-ui/core";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import storage from "services/storage";
import { USER_MANAGEMENT_ROLE, USER_ROLE } from "constants/Roles";

class SidenavContent extends Component {
  constructor() {
    super();
    const data = storage.getData();
    this.state = {
      iconColaborationBlog: "SECADMON_Blog_de_colaboración_Inactive",
      user: data.user
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains("open"))
            ) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) { }
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) { }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector"
      ].some(function (fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) { }

    return null;
  }

  render() {
    const { user } = this.state
    const procedures = window.location.pathname.includes('/procedures');
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="nav-header">
            <IntlMessages id="sidebar.main" />
          </li>
          <li className="menu no-arrow">
            <NavLink
              className="prepend-icon sidenav__collaborationBlogOption"
              to="/app/blog"
            >
              <i className="zmdi icon-SEJ_Inicio zmdi-hc-fw"></i>
              <span className="nav-text customColor">Mi muro</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink
              className="prepend-icon sidenav__profileOption"
              to="/app/profile"
            >
              <i className="zmdi icon-SEJ_Mi_expediente zmdi-hc-fw" />
              <span className="nav-text customColor">Mi expediente</span>
            </NavLink>
          </li>
          <li className={`ui_tooltip menu sidenav__proceduresMulti ${procedures ? 'selected' : ''}`}>
            <Button>
              <i className="zmdi icon-SEJ_Reportes zmdi-hc-fw" />
              <span className="nav-text customColor">Trámites y servicios</span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/procedures">
                  <span className="nav-text">Vista principal</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/procedures/my-procedures">
                  <span className="nav-text">Mi trámite</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/procedures/my-procedures/requests">
                  <span className="nav-text">Trámites a mi cargo</span>
                </NavLink>
              </li>
            </ul>
          </li>

          {!user.rol.includes(USER_ROLE) && <li className="menu no-arrow">
            <NavLink
              className="prepend-icon sidenav__workGroupsOption"
              to="/app/work-groups"
            >
              <i className="zmdi zmdi-assignment-check zmdi-hc-fw" />
              <span className="nav-text customColor">
                Seguimiento a trámites
              </span>
            </NavLink>
          </li>}
          {/*<li className="menu no-arrow">
            <NavLink
              className="prepend-icon sidenav__collaboratorsOption"
              to="/app/collaborators"
            >
              <i className="zmdi icon-SEJ_Tramites zmdi-hc-fw" />
              <span className="nav-text customColor">Reportes</span>
            </NavLink>
          </li>*/}
          {/*{this.state.user?.rol.includes(USER_MANAGEMENT_ROLE) && (
            <li className="menu no-arrow">
              <NavLink
                className="prepend-icon sidenav__usersOption"
                to="/app/users"
              >
                <i className="zmdi zmdi-account-box zmdi-hc-fw" />
                <span className="nav-text customColor">Usuarios</span>
              </NavLink>
            </li>
          )}*/}
        </ul>
        <div
          style={{
            position: "absolute",
            bottom: "20px"
          }}
          className="text-center"
        >
          <img
            src={require("assets/images/secadmon_logo_gobierno_jal.png")}
            alt="Jalisco"
            title="Jalisco"
            width="45%"
            style={{ objectFit: "contain" }}
          />
        </div>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
