import React from "react";
import moment from "moment";
import 'moment/locale/es';

moment.locale("es")

const DisplayDate = ({date}) => {
  const postDate = moment(date);
  const currentDate = moment(new Date());
  const duration = moment.duration(currentDate.diff(postDate));
  const minutes = (duration.asMinutes() | 0);
  const hours = (duration.asHours() | 0);
  switch (true) {
    case minutes === 0:
      return <p className="jr-text-grey post-content jr-fs-sm jr-mb-0 mt-0 mb-0">Ahora</p>;
    case minutes < 60:
      return <p className="jr-text-grey post-content jr-fs-sm jr-mb-0 mt-0 mb-0">{minutes} min</p>;
    case hours < 24:
      return <p className="jr-text-grey post-content jr-fs-sm jr-mb-0 mt-0 mb-0">{hours} horas</p>;
    default:
        return <p className="jr-text-grey post-content jr-fs-sm jr-mb-0 mt-0 mb-0">{postDate.format("DD-MM-YYYY")}</p>
    }
};

export default DisplayDate;
