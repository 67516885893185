import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const CustomScrollbars = (props) => {
    return <Scrollbars  {...props} autoHide
        renderTrackHorizontal={props => <div {...props}
            style={{ display: 'none' }}
            className="track-horizontal" />}
         renderView={props => <div className={`scrollbar-control ${props.className}`} {...props}>{props.children}</div>}
    />;
}

export default CustomScrollbars;