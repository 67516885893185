import { sendRequest } from "util/utils";
import storage from "services/storage";

const staticUsers = {
  "pruebas1@jaliscoedu.mx": {
    "nom_emp": "Despacho del Secretario",
    "rfc": "ROCA0034TY12",
    "num_afil_imss": "345411638",
    "edo_nac": "Jalisco",
    "sexo": "M",
    "curp": "ROCA003O2034WTRH03",
    "niv_academico": "Doctorado",
    "fec_nac": "04 de enero de 1980",
    "edo_civil": "Casado",
    "emp_dom": "Uruguay, 3392",
    "emp_dom_col": "Jardines de la Cruz",
    "emp_dom_pob": "Guadalajara",
    "emp_dom_cp": "00039",
    "correo": "pruebas1@jaliscoedu.mx",
    "bco_admdor": "341",
    "emp_cta_bancaria": "0039102039",
  },
  "pruebas2@jaliscoedu.mx": {
    "nom_emp": "Adrián López Morales",
    "rfc": "LOMA6021FG17",
    "num_afil_imss": "275456691",
    "edo_nac": "Jalisco",
    "sexo": "M",
    "curp": "LOMA44312034HTCV04",
    "niv_academico": "Licenciatura",
    "fec_nac": "15 de febrero de 1990",
    "edo_civil": "Soltero",
    "emp_dom": "San Felipe, 4492",
    "emp_dom_col": "18 de Marzo",
    "emp_dom_pob": "Guadalajara",
    "emp_dom_cp": "44960",
    "correo": "pruebas2@jaliscoedu.mx",
    "bco_admdor": "525",
    "emp_cta_bancaria": "1232002081",
  },
  "pruebas3@jaliscoedu.mx": {
    "nom_emp": "Fernando Torres Aguilar",
    "rfc": "TOAF3921TR45",
    "num_afil_imss": "562419499",
    "edo_nac": "Jalisco",
    "sexo": "M",
    "curp": "TOAF57832034WQGV34",
    "niv_academico": "Maestria",
    "fec_nac": "27 de julio de 1985",
    "edo_civil": "Casado",
    "emp_dom": "Palma sola, 320",
    "emp_dom_col": "Lopez de legaspi",
    "emp_dom_pob": "Guadalajara",
    "emp_dom_cp": "44960",
    "correo": "pruebas3@jaliscoedu.mx",
    "bco_admdor": "540",
    "emp_cta_bancaria": "1402002029",
  },
  "pruebastramites@jaliscoedu.mx": {
    "nom_emp": "Liliana Dorantes Coronado",
    "rfc": "COVL8410RR98",
    "num_afil_imss": "961019232",
    "edo_nac": "Jalisco",
    "sexo": "F",
    "curp": "COVL4940034DRFV97",
    "niv_academico": "Maestria",
    "fec_nac": "15 de mayo de 1986",
    "edo_civil": "Casada",
    "emp_dom": "Constitución, 459",
    "emp_dom_col": "Tonalá, Centro",
    "emp_dom_pob": "Tonalá",
    "emp_dom_cp": "45400",
    "correo": "pruebastramites@jaliscoedu.mx",
    "bco_admdor": "940",
    "emp_cta_bancaria": "2429304323",
  }
}

const login = async data => {
  const route = "/login/google";
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
    body: JSON.stringify(data)
  };
  return await sendRequest(route, request);
};

const getUserList = async (historical = false, page = 1, term = "") => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users?historical=${historical}&page=${page}&term=${term}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
}

const createUser = async (user) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users`,
    request = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        Authorization: token
      },
      body: user
    };
  return await sendRequest(route, request);
};

const editUser = async (userId, user) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users/${userId}`,
    request = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        Authorization: token
      },
      body: user
    };
  return await sendRequest(route, request);
};

const editProfile = async (user) => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/users`,
    request = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        Authorization: token
      },
      body: user
    };
  return await sendRequest(route, request);
};

const getUser = async userId => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/${userId}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

const getCurrentUser = async userId => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/authenticated_user`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

const getUserExternal = async email => {
  if (staticUsers[email]) {
    return { empleado: staticUsers[email], success: true }
  }
  const token = `Bearer ${storage.getToken()}`;
  const route = `users/external?email=${email}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

const changeUserStatus = async (userId, status) => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/${userId}/status`,
    request = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      },
      body: JSON.stringify({ status })
    };
  return await sendRequest(route, request);
}

const searchUsers = async (term = "") => {
  const token = `Bearer ${storage.getToken()}`;
  const route = `/users/rol-follow-up-link-to-procedure`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

const getWorkAreasList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_areas`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
}

const getWorkGroupsList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/work_groups/selector`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
}

const getOrganizationsList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/organizations`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
}

const getRolesList = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = `/roles`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
}

export default {
  login,
  getUserList,
  createUser,
  editUser,
  editProfile,
  getUser,
  changeUserStatus,
  searchUsers,
  getWorkAreasList,
  getWorkGroupsList,
  getOrganizationsList,
  getRolesList,
  getCurrentUser,
  getUserExternal
};
