import React, { useState } from "react";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as Yup from "yup";
import { REQUIRED, EMAIL } from "constants/Validations";
import api from "services/api";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import GoogleBtn from "components/GoogleBtn";
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "constants/Messages"

const schema = Yup.object().shape({
  email: Yup.string()
      .email(EMAIL)
      .required(REQUIRED),
  password: Yup.string().required(REQUIRED)
});

const SignIn = props => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isOpenSnack, setIsOpenSnack] = useState(false);
  const [response, setResponse] = useState({});

  const handleRequestCloseSnackbar = () => {
    setIsOpenSnack(false);
  };
  const { register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(schema)
  });
  const onSubmit = async data => {
    setMessage("");
    setLoading(true);
    let response = await api.user.login(data);
    if (response.success) {
      localStorage.setItem("data", JSON.stringify(response));
      setLoading(false);
      response.user.type === "Administrador"
          ? props.history.push("/app/users")
          : props.history.push("/app/benefactors");
    } else {
      setMessage(response.message || DEFAULT_REQUEST_ERROR_MESSAGE);
      setLoading(false);
    }
    setResponse(response);
    setIsOpenSnack(true);
  };

  return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            {/*<img
                src={require("assets/images/logoPlatCiu2.png")}
                alt="logo"
                style={{ width: "100%" }}
            />*/}
          </div>

          <div className="app-login-content">
            <div className="app-login-header text-center mb-4">
              <h1>Iniciar sesión</h1>
            </div>
            <div className="app-login-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    error={Boolean(errors.email)}
                    label="Correo electrónico"
                    id="email"
                    name="email"
                    autoComplete="email"
                    inputRef={register}
                    helperText={errors.email?.message}
                    fullWidth
                    margin="normal"
                    className="m-0 mb-2"
                />
                <TextField
                    error={Boolean(errors.password)}
                    type="password"
                    id="password"
                    name="password"
                    label="Contraseña"
                    inputRef={register}
                    helperText={errors.password?.message}
                    fullWidth
                    margin="normal"
                    className="m-0 mb-2"
                />
                <div className="mb-3 d-flex align-items-center justify-content-between">
                  {loading ? (
                      <div className="text-center w-100">
                        <CircularProgress />
                      </div>
                  ) : (
                      <GoogleBtn/>
                  )}
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    autoHideDuration={15000}
                    open={isOpenSnack}
                    onClose={handleRequestCloseSnackbar}
                    ContentProps={{
                      "aria-describedby": "message-id",
                      className: response.success ? "bg-green" : "bg-danger"
                    }}
                    message={<span id="message-id">{response.message}</span>}
                    action={[
                      <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={handleRequestCloseSnackbar}
                      >
                        <CloseIcon />
                      </IconButton>
                    ]}
                />
              </form>
            </div>
          </div>
        </div>

        {/* {!!message && NotificationManager.error(message)}
      <NotificationContainer /> */}
      </div>
  );
};

export default SignIn;
