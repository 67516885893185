import { sendRequest } from "util/utils";
import storage from "services/storage";


const createSchedule = async (data, id) => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/schedules/${id}`;
    const request = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: token
        },
        body: JSON.stringify(data)
    };
    return await sendRequest(route, request);
};

const getSchedules = async () => {
    const token = `Bearer ${storage.getToken()}`;
    const route = `/schedules`;
    const request = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: token
        },
    };
    return await sendRequest(route, request);
};

export default
    {
        createSchedule,
        getSchedules
    }