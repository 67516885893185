import storage from "services/storage"
import { sendRequest } from "util/utils";

const listNotifications = async () => {
  const token = `Bearer ${storage.getToken()}`,
    route = "/notifications",
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: token
      }
    };
  return await sendRequest(route, request);
};

const deleteNotification = async (id) => {
    const token = `Bearer ${storage.getToken()}`,
      route = `/notifications/${id}`,
      request = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          Authorization: token
        }
      };
    return await sendRequest(route, request);
};

export default {
  listNotifications,
  deleteNotification
};
