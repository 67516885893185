const getData = () => {
  return JSON.parse(sessionStorage.getItem("data"));
};

const getToken = () => {
  let token,
    data = null;
  try {
    data = JSON.parse(sessionStorage.getItem("data"));
    if (!!data) {
      token = data.token.access_token ? data.token.access_token : false;
    }
  } catch (e) {
    data = "";
  }
  return token;
};

const getRefreshToken = () => {
  let token,
    data = null;
  try {
    data = JSON.parse(sessionStorage.getItem("data"));
    if (!!data) {
      token = data.token.refresh_token ? data.token.refresh_token : false;
    }
  } catch (e) {
    data = "";
  }
  return token;
};

const getUserRole = () => {
  let role,
    data = null;
  try {
    data = JSON.parse(sessionStorage.getItem("data"));
    if (!!data) {
      role = data?.user?.rol;
    }
  } catch (e) {
    data = "";
  }
  return role;
};

const setUser = user => {
  const data = JSON.parse(sessionStorage.getItem("data"));
  data.user = user;
  setStorage(data);
};

const setProceduresIds = ids => {
  const data = JSON.parse(sessionStorage.getItem("data"));
  data.user.procedures_ids = ids;
  setStorage(data);
};

const updateToken = token => {
  const data = JSON.parse(sessionStorage.getItem("data"));
  data.token = token;
  setStorage(data);
};

const setStorage = data => {
  sessionStorage.setItem("data", JSON.stringify(data));
};

const removeToken = () => {
  let data = JSON.parse(sessionStorage.getItem("data"));
  if (!!data) {
    data = "";
  }
  return setStorage(data);
};

const getSurvey = () => {
  let satisfaction_survey,
    data = null;
  try {
    data = JSON.parse(sessionStorage.getItem("data"));
    if (!!data) {
      satisfaction_survey = data.user.satisfaction_survey;
    }
  } catch (e) {
    data = "";
  }
  return satisfaction_survey;
};

const setSurvey = satisfaction_survey => {
  let data = null;
  try {
    data = JSON.parse(sessionStorage.getItem("data"));
    if (!!data) {
      data.user.satisfaction_survey = satisfaction_survey;
      setStorage(data);
    }
  } catch (e) {
    data = "";
  }
};

export default {
  getData,
  getToken,
  getRefreshToken,
  getUserRole,
  setUser,
  updateToken,
  setStorage,
  removeToken,
  getSurvey,
  setSurvey,
  setProceduresIds
};
