import React from "react";
import { withRouter } from "react-router-dom";
import api from "services/api";
import GoogleBtn from "components/GoogleBtn";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      response: {},
      googleResponse: {},
      message: "",
      loading: false,
      isOpenSnack: false,
      user: null,
      token: ""
    };
  }

  handleRequestCloseSnackbar = () => {
    this.setState({ isOpenSnack: false });
  };

  onSubmit = async () => {
    const { googleResponse } = this.state;
    const token = googleResponse.accessToken;
    const data = {
      token
    };
    this.setState({ message: "", loading: true });
    const response = await api.user.login(data);
    if (response.success) {
      sessionStorage.setItem("data", JSON.stringify(response));
      this.setState({ loading: false });
      this.props.history.push("/app/blog");
    } else {
      this.setState({
        loading: false
      });
    }
    this.setState({
      response,
      isOpenSnack: true,
      message:
        response.message ||
        'Ingresa con tu correo institucional de g-suite con terminación "jalisco.edu.mx".'
    });
  };

  setGoogleResponse = googleResponse => {
    /*if (!/jalisco\.gob\.mx$/.test(googleResponse?.profileObj?.email)) return;*/
    if(!/jaliscoedu\.mx$/.test(googleResponse?.profileObj?.email)) return;
    this.setState({ googleResponse });
  };

  render() {
    const { isOpenSnack, response, loading, message } = this.state;
    const text = "Inicia sesión con tu cuenta de Google";
    const logoutText = "Cerrar sesión";
    return (
      <div className="app-main-content-wrapper">
        <div className="login-container flex-column d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="login-content text-center">
                <div className="login-header">
                  <img
                    src={require("assets/images/SEJ_Logo_Educación_Logo_Educación.png")}
                    alt="logo-secadmon"
                    title="logo-secadmon"
                    style={{ width: "40%" }}
                  />
                </div>
                <div className="app-login-header text-center mb-4">
                  <h2>Inicio de sesión</h2>
                </div>

                <div
                  className="text-left mb-4 ml-1 mr-1"
                  style={{ lineHeight: "1.6", fontSize: "12px" }}
                >
                  <p style={{ fontFamily: "Nutmeg-Book", color: "#606469" }}>
                    Para poder iniciar sesión es necesario que ingreses mediante
                    tu correo institucional de Google, confirma que la
                    terminación sea
                    <span style={{ color: "#606469" }}>
                      {" "}
                      <b>jalisco.edu.mx</b>
                    </span>
                  </p>
                </div>

                <div className="login-form">
                  <form method="post" action="/">
                    <div className="mb-3">
                      {loading ? (
                        <div className="text-center w-100">
                          <CircularProgress />
                        </div>
                      ) : (
                        <GoogleBtn
                          googleResponse={this.setGoogleResponse}
                          text={text}
                          submit={this.onSubmit}
                          logoutText={logoutText}
                        />
                      )}
                    </div>
                  </form>
                </div>
                <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  autoHideDuration={15000}
                  open={isOpenSnack}
                  onClose={this.handleRequestCloseSnackbar}
                  ContentProps={{
                    "aria-describedby": "message-id",
                    className: response.success ? "bg-green" : "bg-danger"
                  }}
                  message={<span id="message-id">{message}</span>}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={this.handleRequestCloseSnackbar}
                    >
                      <CloseIcon />
                    </IconButton>
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <img
                className="mx-auto d-block height-100"
                src={require("assets/images/secadmon_logo_gobierno_jal.png")}
                alt="logo-gov-jal"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
