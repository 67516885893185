import React from 'react';

import NotificationItem from './NotificationItem';
//import {notifications} from './data';
import CustomScrollbars from 'util/CustomScrollbars';

const AppNotification = (props) => {
  const {notifications} = props;
  return (
    <CustomScrollbars className="messages-list scrollbar" style={{height: 280}}>
      {
        notifications.length
        ? <ul className="list-unstyled">
          {
            notifications.map((notification, index) => 
              <NotificationItem
                key={index}
                notification={notification}
                history={props.history}
                deleteNotification={props.deleteNotification}
              />
            )
          }
        </ul>
        : <span>No hay notificaciones para mostrar</span>
      }
    </CustomScrollbars>
  )
};

export default AppNotification;

